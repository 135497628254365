<template>
  <div class="devices">
    <base-section id="theme-features">
      <base-section-heading title="Mis Dispositivos">
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="4"
              md="4"
            >
            <v-switch label="Ver Inactivos" v-model="viewInactive" />
            </v-col>
            <v-col>
              <add-device :types="types" :iconList="iconList" />
            </v-col>
          </v-row>
        </v-container>
        </base-section-heading>
        <v-container >
          <v-row>
              <flip-card :cards="items" :types="types" :iconList="iconList" @reload="loadDevices" :viewInactive="viewInactive"  />
          </v-row>
        </v-container>
    </base-section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  metaInfo: { title: 'My Devices' },
    components: {
        flipCard: () => import('./sections/devices/FlipCard'),
        addDevice: () => import('./sections/devices/addDevice')
    },
    data:()=>({
        items:[ ],
        types:[]
        ,viewInactive:false
        ,iconList:[{
            image:'icons/truck01.jpg',
            name: 'image 1'
            },
            {
            image:'icons/logo.svg',
            name:'image 2'
            },{
            image:'images/truck01.png',
            name:'Truck 01'
            },
        ]
    }),
    mounted(){
        this.loadIcons();
        this.loadTypes();
        this.loadDevices();        
    },
    computed:{
      currentUserId(){
        return this.$route.params?.id || this.$store.state.user.id;
      }
    },
    methods:{
        ...mapActions(['getUserDevices','getDeviceTypes']),

        loadIcons(){
          this.iconList = this.$func.getIconList();
        },
        loadTypes(){
            this.getDeviceTypes().then((res)=>{
                this.types = res.data.data;
                //console.log(this.types);
            }).catch((err)=>{
                console.log(err);
            });
        },
        loadDevices(){
          var userid = this.currentUserId;
          this.getUserDevices(userid).then((res) => {
              this.items = res.data.data.map((d)=>{return {...d,flipped:false}});

              for(const it of this.items) {
                  it.visible = true;
              }
              //console.log(this.items);
          })
          .catch((err)=>{
              console.log(err);
          });
        }
    }
}
</script>

<style>

</style>